<template>
  <div class="stencil">
    <div class="search">
      <el-input v-model="keyword" suffix-icon="el-icon-search" placeholder="搜索" @change="search"></el-input>
    </div>
    <div class="filter">
      <el-select v-model="filter" placeholder="请选择">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div>
    <el-collapse class="collapse" accordion :value="filter">
      <!-- 基础图形 -->
      <el-collapse-item :title="base.name" :name="base.id">
        <div class="item-content">
          <ul class="shape-list base">
            <li class="shape-item" v-for="shape in base.shapes" :key="shape.icon" @mousedown="startDrag({ node: shape.node, e: $event })">
              <i class="iconfont" :class="shape.icon"></i>
            </li>
          </ul>
        </div>
      </el-collapse-item>
      <!-- 设备模型 -->
      <el-collapse-item :title="model.name" :name="model.id">
        <div class="item-content">
          <ul class="shape-list model">
            <li class="shape-item" v-for="shape in model.filter" :key="shape.picPath" @mousedown="startDrag({ node: shape.node, nodeName: shape.name, e: $event })">
              <div class="item-wrap">
                <img :src="shape.picPath" :alt="shape.name" user-select="none" />
              </div>
              <p class="shape-name">{{ shape.name }}</p>
            </li>
          </ul>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { Addon } from '@antv/x6';
import { Rect, RRect, Circle, Triangle, Diamond, Cylinder, User, Cloud, Polygon, Text, createImageNode } from '../shape/preset.shape';
export default {
  data() {
    return {
      // 拖拽对象
      dnd: null,
      filter: '0',
      options: [
        {
          value: '0',
          label: '所有分类'
        },
        {
          value: '1',
          label: '基础原件'
        },
        {
          value: '2',
          label: '设备模型'
        }
      ],
      base: {
        id: '1',
        name: '基础图形',
        shapes: [
          {
            icon: 'icon-dcyuanjiaojuxing',
            node: RRect
          },
          {
            icon: 'icon-dcjcyj_icon01',
            node: Rect
          },
          {
            icon: 'icon-dcjcyj_icon02',
            node: Circle
          },
          {
            icon: 'icon-dcjcyj_icon03',
            node: Triangle
          },
          {
            icon: 'icon-dcjcyj_icon04',
            node: Diamond
          },
          {
            icon: 'icon-dcjcyj_icon05',
            node: Cylinder
          },
          {
            icon: 'icon-dcjcyj_icon06',
            node: User
          },
          {
            icon: 'icon-dcjcyj_icon07',
            node: Cloud
          },
          {
            icon: 'icon-dcjcyj_icon08',
            node: Polygon
          },
          {
            icon: 'icon-dcjcyj_icon09',
            node: Text
          }
        ]
      },
      model: {
        id: '2',
        name: '设备模型',
        shapes: [],
        filter: []
      },
      shaps: [],
      keyword: ''
    };
  },
  created() {
    // 电子地图素材
    const picTypeId = 10;
    this.$apis.imgModel.imgList({ picTypeId }).then(data => {
      this.model.shapes = data.map(pic => {
        return {
          ...pic,
          node: createImageNode(process.env.VUE_APP_ICONIMG_URL + pic.picPath + pic.fileName),
          picPath: process.env.VUE_APP_ICONIMG_URL + pic.picPath + pic.fileName
        };
      });
      this.model.filter = this.model.shapes;
    });
  },
  methods: {
    // 初始化拖拽能力
    initDnd(graph) {
      this.dnd = new Addon.Dnd({
        target: graph,
        animation: true
      });
    },
    // 拖拽事件
    startDrag({ node, nodeName, e }) {
      // 携带设备模型的设备名称
      if (nodeName) {
        node.label = nodeName;
        node.setAttrs({
          label: {
            fill: '#fff',
            refY2: '80%'
          }
        });
      }
      this.dnd.start(node, e);
    },
    search(v) {
      if (v === '') {
        this.model.filter = this.model.shapes;
      }
      this.model.filter = this.model.shapes.filter(shape => shape.name.includes(v));
      this.filter = this.model.id;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../style/index.scss';
.stencil {
  padding: 16px;
  border-right: 1px solid $boder-color;
  background: #141414;
  // position: relative;
  // top: 50px;
}
/deep/ .el-collapse {
  border: none;
  margin: 0 -16px;
  .el-collapse-item {
    background: transparent;
    border-bottom: 1px solid $boder-color !important;
    border: none;
    .el-collapse-item__header,
    .el-collapse-item__wrap {
      padding: 0 16px;
    }
    .el-collapse-item__header {
      background: transparent;
      border: none;
      font-size: 14px;
      color: #f3f3f3;
      font-weight: bold;
    }
    .el-collapse-item__wrap {
      background: transparent;
      border: none;
    }
    .el-collapse-item__content {
      padding: 0;
    }
    .item-content {
      .shape-list {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 6px;
        .shape-item {
          margin-bottom: 10px;
          border-radius: 0px 0px 0px 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .iconfont {
            font-size: 24px;
          }
          .model {
            color: #fff;
            text-align: center;
          }
        }
      }
      .base {
        justify-content: space-between;
        .shape-item {
          width: 62px;
          height: 62px;
          color: #888;
          background: $secondary-color;
        }
      }
      .model {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .shape-item {
          display: flex;
          .item-wrap {
            width: 98px;
            line-height: 98px;
            background: #0d0d0d;
            img {
              width: 65%;
            }
          }
          .shape-name {
            width: 98px;
            color: #fff;
            font-size: 12px;
            margin-top: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.search,
.filter {
  /deep/ .el-input,
  .el-selectl {
    width: 100%;
    color: #5a5a5a;
    ::placeholder {
      color: #5a5a5a;
    }
    .el-input__inner {
      border: none;
      color: #909399;
      background: $secondary-color;
    }
  }
}
.search {
  margin-top: 16px;
}
.filter {
  margin: 16px 0;
}
/deep/ .collapse {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .el-collapse-item {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .el-collapse-item__wrap {
      overflow-y: auto;
      scrollbar-width: none;
    }
  }
}
/deep/ .collapse {
  ::-webkit-scrollbar {
    width: 0;
  }
}
</style>
