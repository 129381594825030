<template>
  <div class="contextMenu">
    <ul class="menu-list">
      <li class="menu-item" @click="bind" v-if="!isPresetShape">绑定设备</li>
      <li class="menu-item" @click="reduceZIndex">层级置底</li>
      <li class="menu-item" @click="editStyle" v-if="isPresetShape">编辑样式</li>
      <li class="menu-item" @click="del">删除</li>
    </ul>
  </div>
</template>

<script>
const router = require('@/router/index').default;
export default {
  inject: ['getGraph', 'getNode'],
  props: ['node_data_id'],
  data() {
    return {
      VueShape: this.getNode(),
      currentNode: this.getGraph()
        .getNodes()
        .filter(node => node.id === this.node_data_id)[0]
    };
  },
  methods: {
    // 删除 node
    del() {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.getGraph().removeNode(this.node_data_id);
          this.destroy();
        })
        .catch(() => {
          this.destroy();
        });
    },
    // 层级置底
    reduceZIndex() {
      this.currentNode.setZIndex(0);
      this.destroy();
    },
    // 绑定设备
    bind() {
      router.replace({
        path: '/map/editor/bind',
        query: {
          id: this.node_data_id
        }
      });
      this.destroy();
    },

    // 编辑自定义形状的样式
    editStyle() {
      this.$emit('style', this.currentNode);
      this.destroy();
    },
    destroy() {
      const curContextMenuId = this.VueShape.id;
      this.getGraph().removeNode(curContextMenuId);
    }
  },
  computed: {
    isPresetShape() {
      const type = this.currentNode.shape;
      return type === 'path' || type === 'rect';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
@import '../style/index.scss';
.contextMenu {
  background: $secondary-color;
  .menu-list {
    .menu-item {
      color: #c0c0c0;
      line-height: 30px;
      padding-left: 10px;
      cursor: pointer;
      &:hover {
        color: #f39800;
        background: #161616;
      }
    }
  }
}
</style>
