import '@antv/x6-vue-shape';
import { Graph } from '@antv/x6';
import ContextMenu from '../components/contextMenu';
/**
 * 给 graph 绑定事件
 * ctrl + c 剪切节点/边
 * ctrl + v 粘贴节点/边
 * ctrl + z 上一步
 * ctrl + y 下一步
 * Mac系统的 ctrl 键是 meta
 */
export default function () {
  const u = navigator.userAgent.includes('Mac');
  const key = u ? 'meta' : 'ctrl';

  // Ctrl + C 拷贝节点
  this.graph.bindKey(`${key}+c`, () => {
    const cells = this.graph.getSelectedCells();
    if (cells.length) {
      this.graph.copy(cells);
    }
    return false;
  });
  // Ctrl + V 粘贴节点
  this.graph.bindKey(`${key}+v`, () => {
    this.copy();
  });
  // Ctrl + Z 返回上一步
  this.graph.bindKey(`${key}+z`, () => {
    this.undo();
  });
  // Ctrl + Y 取消返回上一步
  this.graph.bindKey(`${key}+y`, () => {
    this.redo();
  });
  // 节点大小改变时
  this.graph.on('node:resizing', ({ node }) => {
    const { width } = node.size();
    const size = width / 4.8;
    // 改变字体大小
    node.attr('label/fontSize', size);
    node.setAttrs({
      label: {
        fontSize: size
      }
    });
  });
  // 鼠标点击画布空白区域
  this.graph.on('blank:click', () => {
    this.removeNode();
  });
  // 鼠标右击画布空白区域
  this.graph.on('blank:contextmenu', () => {
    this.removeNode();
  });
  // 双击节点编辑文字
  this.graph.on('node:dblclick', ({ node, e }) => {
    node.addTools({
      name: 'node-editor',
      args: {
        event: e
      }
    });
    node.setAttrs({
      label: {
        fill: '#fff',
        refY2: '80%'
      }
    });
  });
  // 单机任意节点时也销毁 VueShapNode
  this.graph.on('node:click', ({ node }) => {
    if (node.shape !== 'vue-shape') {
      this.removeNode();
    }
  });
  // 鼠标右击节点
  this.graph.on('node:contextmenu', ({ node, x, y }) => {
    this.graph.unselect(this.graph.getSelectedCells());
    this.removeNode();
    Graph.registerVueComponent(
      'contextmenu',
      {
        template: `<context-menu node_data_id="${node.id}" @style="style"></context-menu>`,
        methods: {
          style: node => {
            this.$refs.picker.handleVisible(node);
          }
        },
        components: {
          ContextMenu
        }
      },
      true
    );
    this.graph.addNode({
      id: 'vue-shape-contextmenu',
      shape: 'vue-shape',
      x,
      y,
      width: 100,
      height: 120,
      component: 'contextmenu',
      sx: 19,
      sy: 10
    });
  });
  // 监听空格按下
  this.graph.bindKey(
    'space',
    () => {
      if (!this.graph.isPannable()) {
        this.graph.toggleSelection();
        this.graph.togglePanning();
        this.$refs.toolbar.list[1].enabled = true;
      }
    },
    'keydown'
  );
  // 监听空格抬起
  this.graph.bindKey(
    'space',
    () => {
      this.graph.toggleSelection();
      this.graph.togglePanning(false);
      this.$refs.toolbar.list[1].enabled = false;
    },
    'keyup'
  );
  // 监听 delete 键
  this.graph.bindKey(
    'delete',
    () => {
      const cellIds = this.graph.getSelectedCells().map(cell => cell.id);
      this.removeNode('', cellIds);
    },
    'keyup'
  );
  // 移动任意节点时删除 VueShapNode
  this.graph.on('node:move', () => {
    this.removeNode();
  });
}
