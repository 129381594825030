import { Shape } from '@antv/x6';

Shape.Edge.config({
  attrs: {
    line: {
      stroke: '#F39800 ',
      strokeWidth: '1',
      targetMarker: 'classic'
    }
  },
  router: 'manhattan'
});
