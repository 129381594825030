import { Shape } from '@antv/x6';
import './config';

const width = 24;
const height = 24;
const attrs = {
  body: {
    stroke: '#888888', // 边框颜色
    fill: 'rgba(255, 255, 255, 0)' // 填充背景颜色
  }
};

const data = {
  parent: true // 基础图形可以作为群组的父节点
};

// 矩形
const Rect = new Shape.Rect({
  width,
  height,
  attrs,
  data
});

// 圆角矩形
const RRect = new Shape.Path({
  width,
  height,
  attrs,
  data,
  path: 'M224 96h576a128 128 0 0 1 128 128v576a128 128 0 0 1-128 128H224a128 128 0 0 1-128-128V224a128 128 0 0 1 128-128z'
});

// 圆形
const Circle = new Shape.Path({
  width,
  height,
  attrs,
  data,
  path: 'M554.666667 981.333333C307.626667 981.333333 106.666667 780.373333 106.666667 533.333333S307.626667 85.333333 554.666667 85.333333 1002.666667 286.293333 1002.666667 533.333333 801.706667 981.333333 554.666667 981.333333z'
});

// 三角形
const Triangle = new Shape.Path({
  width,
  height,
  attrs,
  data,
  path: 'M949.290667 896h-832A31.957333 31.957333 0 0 1 85.333333 863.616c0-5.632 1.578667-11.178667 4.437334-16.042667l415.658666-705.024c11.52-19.456 43.52-19.456 55.04 0l412.842667 700.202667A32 32 0 0 1 949.333333 896z'
});

// 菱形
const Diamond = new Shape.Path({
  width,
  height,
  attrs,
  data,
  path: 'M0 512l512 512 512-512L512 0 0 512z'
});

// 圆柱体
const Cylinder = new Shape.Path({
  width,
  height,
  attrs,
  data,
  path: 'M 380 84 C 380 65.33 450 65.33 450 84 L 450 126 C 450 144.67 380 144.67 380 126 Z M 380 84 C 380 98 450 98 450 84'
});

// 用户
const User = new Shape.Path({
  width,
  height,
  attrs,
  data,
  path: 'M534.613333 136.149333c115.626667 0 169.6 61.013333 169.6 195.669334 0 104.192-23.125333 182.997333-64.256 228.693333l-25.685333 27.989333c-15.402667 15.232-5.12 40.661333 15.402667 45.738667l38.570666 7.637333c82.218667 17.792 156.714667 48.256 192.682667 66.090667 7.722667 2.517333 15.445333 12.672 15.445333 20.309333l12.842667 86.4c0 7.637333 0 15.232-5.12 20.309334-5.12 5.12-12.885333 10.197333-20.565333 10.197333H208.298667a23.466667 23.466667 0 0 1-20.565334-10.197333c-5.12-5.077333-7.68-12.672-5.12-20.309334l12.8-86.4c2.56-10.154667 7.722667-17.792 15.445334-20.309333 35.968-15.274667 110.506667-45.781333 195.285333-66.133333l38.528-7.594667c20.565333-5.077333 28.288-30.506667 15.445333-45.738667l-25.728-27.946666c-43.648-48.298667-64.213333-124.544-64.213333-228.693334C362.496 199.68 418.986667 136.106667 534.613333 136.106667z'
});

// 云
const Cloud = new Shape.Path({
  width,
  height,
  attrs,
  data,
  path: 'M1024 601.728c0-138.709333-109.738667-251.562667-244.650667-251.562667-9.856 0-19.498667 0.768-29.013333 1.92C715.008 245.418667 616.149333 170.666667 504.405333 170.666667c-143.232 0-259.754667 119.808-259.754666 267.093333C133.290667 437.76 42.666667 531.072 42.666667 645.546667 42.666667 760.106667 133.290667 853.333333 244.693333 853.333333h548.394667v-0.725333C921.6 845.226667 1024 735.658667 1024 601.728z m-244.650667 192.256H244.736c-79.616 0-144.341333-66.56-144.341333-148.437333 0-81.792 64.725333-148.394667 144.298666-148.394667h57.728v-59.349333c0-114.56 90.624-207.786667 202.026667-207.786667 85.205333 0 160.512 55.978667 189.269333 136.362667-92.714667 35.754667-159.018667 127.616-159.018666 235.349333h57.728c0-105.984 83.882667-192.170667 186.922666-192.170667s186.922667 86.186667 186.922667 192.170667c0 106.026667-83.882667 192.256-186.922667 192.256z'
});

// 多边形
const Polygon = new Shape.Path({
  width,
  height,
  attrs,
  data,
  path: 'M681.898667 213.376a64.853333 64.853333 0 0 1 55.466666 31.786667l149.205334 256.170666a63.701333 63.701333 0 0 1 0 64l-149.205334 256.128a64.256 64.256 0 0 1-55.466666 32.256H383.488a64.853333 64.853333 0 0 1-55.466667-31.786666L178.773333 565.76a63.701333 63.701333 0 0 1 0-64l149.205334-256.170667a64.256 64.256 0 0 1 55.466666-32.213333h298.410667z'
});

// 文字
const Text = new Shape.Rect({
  width: 100,
  height: 20,
  label: 'text',
  attrs: {
    body: {
      stroke: 'rgba(255, 255, 255, 0)', // 边框颜色
      fill: 'rgba(255, 255, 255, 0)' // 填充背景颜色
    },
    label: {
      fill: '#fff', // 文字颜色
      fontSize: 16 // 文字大小
    }
  }
});

/**
 * 创建图片 node
 * @param {String} path 完整的图片地址
 * @return {imageNode} 图片 node
 */
const createImageNode = path => {
  return new Shape.Image({
    width: 48,
    height: 48,
    attrs,
    imageUrl: path
  });
};

export { Rect, RRect, Circle, Triangle, Diamond, Cylinder, User, Cloud, Polygon, Text, createImageNode };
