<template>
  <div class="picker">
    <el-drawer class="drawer" title="形状样式" direction="rtl" :visible.sync="visible" :modal="false">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="背景颜色">
          <el-color-picker v-model="form.bg" show-alpha :predefine="predefineColors"> </el-color-picker>
        </el-form-item>
        <el-form-item label="边框颜色">
          <el-color-picker v-model="form.border" show-alpha :predefine="predefineColors"> </el-color-picker>
        </el-form-item>
        <el-form-item label="文字颜色">
          <el-color-picker v-model="form.txt" show-alpha :predefine="predefineColors"> </el-color-picker>
        </el-form-item>
        <el-form-item label="线宽">
          <el-input-number v-model="form.borderWidth" :min="1"></el-input-number>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      node: null,
      visible: false,
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      form: {
        bg: '',
        border: '',
        txt: '',
        borderWidth: 2
      }
    };
  },
  methods: {
    handleVisible(node) {
      this.visible = !this.visible;
      this.node = node;
      const { label = {}, body } = node.getAttrs();
      this.form.bg = body.fill || '';
      this.form.border = body.stroke || '';
      this.form.borderWidth = body.strokeWidth || '';
      this.form.txt = label.fill || '';
    }
  },
  watch: {
    form: {
      deep: true,
      handler({ bg, border, borderWidth, txt }) {
        this.node.setAttrs({
          label: {
            fill: txt
          },
          body: {
            stroke: border, // 边框颜色
            fill: bg, // 填充背景颜色
            strokeWidth: borderWidth
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../style/index.scss';
.picker {
  /deep/ .drawer {
    .el-drawer {
      width: 300px !important;
      height: 400px;
      color: #fff;
      background: $secondary-color;
      .el-drawer__header {
        color: #fff;
        font-size: 18px;
      }
      .el-drawer__body {
        padding-left: 21px;
      }
    }
    .el-form-item__label {
      color: #fff;
    }
    .title {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .col {
      padding: 0 20px;
      margin-bottom: 10px;
    }
  }
}
</style>
