<template>
  <div class="editor">
    <header class="header">
      <Toolbar
        ref="toolbar"
        :mapId.sync="mapId"
        @changeMap="changeMap"
        @clearCells="clearCells"
        @back="back"
        @drag="drag"
        @enlarge="enlarge"
        @narrow="narrow"
        @zoomToFit="zoomToFit"
        @undo="undo"
        @redo="redo"
        @copy="copy"
        @concat="concat"
        @save="save"
      />
    </header>
    <main class="main">
      <Stencli class="stencli" ref="stencli" />
      <div id="graph"></div>
    </main>
    <section class="popper">
      <Picker ref="picker" />
    </section>
    <section class="routerview">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import { utf8ToBase64, base64ToUtf8 } from '@/common/js/base64';
import { $main_theme_color } from '../style/index.js';
import Stencli from '../components/stencil';
import Toolbar from '../components/toolbar';
import Picker from '../components/picker';
import generateMap from '../utils/generateMap.js';
import graphEvents from './graphEvents.js';
export default {
  data() {
    return {
      graph: null,
      mapId: '',
      factory: null
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.updateRouteFromData(to, from, next);
  },
  created() {
    this.initMap();
  },
  methods: {
    // 初始化地图
    initMap() {
      this.$nextTick(() => {
        const dom = document.getElementById('graph');
        this.graph = generateMap(dom, {
          snapline: true, // 启用对齐线
          scroller: true, // 启用画布滚动
          rotating: true, // 启用节点角度调整
          resizing: true, // 启用节点大小调整
          history: true, // 启用步骤历史
          keyboard: true, // 启用键盘快捷键
          embedding: true, // 嵌套节点
          // 启用节点可选
          selecting: {
            enabled: true,
            rubberband: true,
            showNodeSelectionBox: true,
            filter: ['vue-shape'] //过滤不可选节点
          },
          // 启用剪切板
          clipboard: {
            enabled: true,
            deep: true,
            useLocalStorage: true
          },
          // 连线规则
          connecting: {
            allowBlank: false,
            allowEdge: true
          },
          // 启用滚轮缩放画布
          mousewheel: {
            enabled: true,
            modifiers: ['ctrl', 'meta']
          },
          background: {
            color: $main_theme_color
          }
        });

        // 绑定 Graph 事件
        graphEvents.apply(this);

        // 初始化拖拽能力
        this.$refs.stencli.initDnd(this.graph);
        this.loadData();
      });
    },
    async loadData(mapId = JSON.parse(localStorage.getItem('FactoryMap')).mapId) {
      if (mapId) {
        this.mapId = mapId;
        const { mapContent } = await this.$apis.electron.electronDetails({ electronMapId: mapId });

        // 渲染 node 和 edge
        if (mapContent) {
          this.graph.fromJSON(JSON.parse(base64ToUtf8(mapContent)));
        } else {
          this.clearCells();
        }
      }
    },
    changeMap(mapId) {
      this.loadData(mapId);
    }, // 加载工厂结构
    async loadStructure() {
      this.factory_list = (await this.$apis.electron.list()).map(factory => {
        return {
          label: factory.factoryName,
          id: factory.factoryId,
          children: factory.electronMapList.map(map => {
            return {
              ...map,
              label: map.name,
              id: map.electronMapId
            };
          })
        };
      });

      localStorage.setItem('factory_list', JSON.stringify(this.factory_list));
    },
    updateRouteFromData(to, from, next) {
      this.loadStructure();
      const addPage = from.name === 'map_editor_add';
      const adminPage = from.name === 'map_editor_admin';
      const bindPage = from.name === 'map_editor_bind';
      if (addPage || adminPage) {
        this.$refs.toolbar.loadData();
      }
      if (bindPage) {
        const bindDeviceNode = JSON.parse(localStorage.getItem('bind_device_node'));
        if (bindDeviceNode) {
          const { nodeId, deviceId } = bindDeviceNode;
          const beforeRouteUpdateNodeId = nodeId;
          const node = this.graph.getNodes().find(cell => cell.id === beforeRouteUpdateNodeId);

          node.setData({ deviceId });
          localStorage.removeItem('bind_device_node');
        }
      }
      next();
    },
    // 页面返回
    back() {
      this.$router.back();
      this.dispose();
    },
    // 开启/关闭 画布拖拽
    drag() {
      this.graph.toggleSelection();
      this.graph.togglePanning();
    },
    // 放大画布
    enlarge() {
      this.graph.zoom(0.1);
    },
    // 缩小画布
    narrow() {
      this.graph.zoom(-0.1);
    },
    // 画布自适应
    zoomToFit() {
      this.graph.zoomToFit();
      this.graph.centerContent();
    },
    // 上一步
    undo() {
      this.graph.undo();
    },
    // 下一步
    redo() {
      this.graph.redo();
    },
    // 拷贝
    copy() {
      const cells = this.graph.getSelectedCells();
      if (cells.length) {
        this.graph.copy(cells);
        if (!this.graph.isClipboardEmpty()) {
          const cells = this.graph.paste({ offset: 32 });
          this.graph.cleanSelection();
          this.graph.select(cells);
        }
      }
    },
    // 启用/关闭 连线
    concat(enabled) {
      // 取消所有节点或边的选中
      this.graph.unselect(this.graph.getSelectedCells());
      if (enabled) {
        this.graph.on('node:mouseenter', ({ node }) => {
          node.attr('body/magnet', true);
          node.attr('image/magnet', true);
        });
      } else {
        this.graph.on('node:mouseenter', ({ node }) => {
          node.attr('body/magnet', false);
          node.attr('image/magnet', false);
        });
      }
    },
    // 保存数据
    async save() {
      if (!this.mapId) {
        this.$message.warning('未选择地图');
        return;
      }

      const nodes = this.graph.getNodes();
      nodes.forEach(node => {
        node.attr('body/magnet', false);
        node.attr('image/magnet', false);
      });

      this.removeNode();

      const mapContent = utf8ToBase64(JSON.stringify(this.graph.toJSON()));
      const params = {
        mapContent,
        electronMapId: this.mapId
      };

      const { fail } = await this.$apis.electron.update(params);
      if (!fail) {
        this.back();
      }
    },
    // 删除指定的节点
    removeNode(id = 'vue-shape-contextmenu', ids = []) {
      if (ids.length) {
        ids.forEach(id => {
          this.graph.removeNode(id);
        });
      } else {
        this.graph.removeNode(id);
      }
    },
    // 情况画布上的节点和边
    clearCells() {
      this.graph.clearCells();
    },
    // 销毁画布以及资源的回收
    dispose() {
      this.graph.dispose();
      this.graph = null;
    }
  },
  components: {
    Stencli,
    Toolbar,
    Picker
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
.editor {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .header {
    height: 50px;
    position: fixed;
    z-index: 1;
  }
  .main {
    display: flex;
    .stencli {
      width: 238px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      margin-top: 50px;
    }
    #graph {
      flex: 1;
      background: #121212;
    }
  }
  /deep/ .x6-graph-scroller {
    overflow: hidden;
  }
}
</style>
