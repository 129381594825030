<template>
  <div class="toolbar">
    <div class="back" @click="back">
      <i class="iconfont icon-dcicon_jt_right_nor-copy-copy"></i>
      <span class="tt">返回</span>
    </div>
    <h2 class="current">{{ factoryName }}</h2>
    <div class="select-wrap">
      <MapSelect ref="MapSelect" class="MapSelect" :value.sync="factoryMapId" :options="factoryMaps" rightIcon @change="selectChange">
        <template v-slot:footer>
          <div class="footer">
            <BaseRow justify="space-around">
              <BaseCol :num="10">
                <el-button class="iconfont icon-dctianjia" @click="add">添加地图</el-button>
              </BaseCol>
              <BaseCol :num="10">
                <el-button class="iconfont icon-dcicon_gl" @click="admin">地图管理</el-button>
              </BaseCol>
            </BaseRow>
          </div>
        </template>
      </MapSelect>
    </div>
    <ul class="toolbar-list">
      <div class="map-select"></div>
      <li class="toolbar-item" v-for="item in toolbar_list" :key="item.icon" @click="item.handle(item)">
        <el-tooltip :content="item.tip" placement="top-start">
          <i class="iconfont" :class="[item.icon, { enabled: item.enabled }]"></i>
        </el-tooltip>
      </li>
    </ul>
  </div>
</template>

<script>
import BaseRow from '@/components/row/base-row';
import BaseCol from '@/components/col/base-col';
import MapSelect from './select.vue';
export default {
  data() {
    return {
      factoryName: '',
      factoryMaps: [],
      factoryMapId: '',
      toolbar_list: [
        {
          icon: 'icon-dcicon_hand',
          enabled: false,
          tip: '移动画布',
          handle: this.drag
        },
        {
          icon: 'icon-dcicon_enlarge1',
          enabled: false,
          tip: '放大',
          handle: this.enlarge
        },
        {
          icon: 'icon-dcicon_narrow1',
          enabled: false,
          tip: '缩小',
          handle: this.narrow
        },
        {
          icon: 'icon-dcicon_location',
          enabled: false,
          tip: '自适应',
          handle: this.zoomToFit
        },
        {
          icon: 'icon-dcicon_Back',
          enabled: false,
          tip: '上一步',
          handle: this.undo
        },
        {
          icon: 'icon-dcicon_next',
          enabled: false,
          tip: '下一步',
          handle: this.redo
        },
        {
          icon: 'icon-dcicon_copy',
          enabled: false,
          tip: '拷贝',
          handle: this.copy
        },
        {
          icon: 'icon-dcicon_line',
          enabled: false,
          tip: '连线',
          handle: this.concat
        },
        {
          icon: 'icon-dcicon-dcbaocun1',
          enabled: false,
          tip: '保存',
          handle: this.save
        }
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const { id, mapId } = JSON.parse(localStorage.getItem('FactoryMap'));
      const { factoryName, electronMapList } = await this.$apis.electron.electronList({ factoryId: id });

      this.factoryName = factoryName;

      if (electronMapList) {
        this.factoryMaps = electronMapList.map(map => {
          return {
            ...map,
            label: map.name,
            id: map.electronMapId
          };
        });

        if (mapId) {
          this.$refs.MapSelect.value = Number(mapId);
        } else {
          const mapId = this.factoryMaps[0].electronMapId;
          this.$refs.MapSelect.value = Number(mapId);
          this.$emit('changeMap', mapId);
        }
      } else {
        const FactoryMap = {
          id: JSON.parse(localStorage.getItem('FactoryMap')).id,
          mapId: ''
        };
        localStorage.setItem('FactoryMap', JSON.stringify(FactoryMap));

        this.factoryMaps = [];
        this.$refs.MapSelect.value = '';
        this.$emit('clearCells');
        // this.$message.warning('');
        this.add();
      }
      this.factoryMapId = this.$refs.MapSelect.value;
    },
    selectChange(val) {
      const FactoryMap = {
        id: JSON.parse(localStorage.getItem('FactoryMap')).id,
        mapId: val
      };
      localStorage.setItem('FactoryMap', JSON.stringify(FactoryMap));

      this.$emit('changeMap', val);
    },
    toggleEnabled(item) {
      item.enabled = !item.enabled;
    },
    back() {
      this.$emit('back');
    },
    drag(item) {
      this.$emit('drag');
      this.toggleEnabled(item);
    },
    enlarge() {
      this.$emit('enlarge');
    },
    narrow() {
      this.$emit('narrow');
    },
    zoomToFit() {
      this.$emit('zoomToFit');
    },
    undo() {
      this.$emit('undo');
    },
    redo() {
      this.$emit('redo');
    },
    copy() {
      this.$emit('copy');
    },
    concat(item) {
      this.toggleEnabled(item);
      this.$emit('concat', item.enabled);
    },
    save() {
      this.$emit('save');
    },
    add() {
      this.$refs.MapSelect.blur();
      this.$router.push('/map/editor/add');
    },
    admin() {
      this.$refs.MapSelect.blur();
      this.$router.push('/map/editor/admin');
    }
  },
  watch: {
    factoryMapId(val) {
      this.$emit('update:mapId', val);
    }
  },
  components: {
    BaseRow,
    BaseCol,
    MapSelect
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
@import '../style/index.scss';
.toolbar {
  width: 100vw;
  height: 50px;
  display: flex;
  align-items: center;
  background: $secondary-color;
  .back {
    padding: 0 20px;
    border-right: 1px solid #222222;
    cursor: pointer;
    .iconfont {
      color: #5a5a5a;
      font-size: 25px;
      margin-right: 7px;
      vertical-align: sub;
    }
    .tt {
      color: #bdbdbd;
    }
  }
  .current {
    color: #f3f3f3;
    font-size: 16px;
    margin-left: 24px;
  }
  /deep/ .select-wrap {
    width: 265px;
    margin-left: 50px;
    .MapSelect {
      .el-select {
        width: 100%;
        .el-button.el-button--default {
          color: #bdbdbd;
          border-color: #1b1b1b;
          background: #000;
          &:hover {
            background: #1b1b1b !important;
          }
        }
      }
      .footer {
        margin: 10px 0 5px;
      }
    }
  }
  .toolbar-list {
    width: 350px;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 10px;
    margin: 0 auto;
    transform: translateX(-50%);
    position: relative;
    left: -50px;
    .toolbar-item {
      .iconfont {
        font-size: 20px;
        color: #888;
        cursor: pointer;
        margin-top: -5px;
        &:hover {
          color: $--color-primary;
        }
        &.enabled {
          color: $--color-primary;
        }
      }
    }
  }
}
</style>
